import http from "../api/httpRequest.js";
import axios from "axios";
import { TERMINAL_TYPE } from "../api/enums.js";

export default {
  state: {
    friends: [],
    activeFriend: null,
    timer: null,
    currentDeptIds: [],
  },
  mutations: {
    //部门选中的id
    updateCurrentDeptIds(state, ids) {
      state.currentDeptIds = ids;
    },
    setFriends(state, friends) {
      friends.forEach((f) => {
        f.online = false;
        f.onlineWeb = false;
        f.onlineApp = false;
      });
      state.friends = friends;
    },
    updateFriend(state, friend) {
      state.friends.forEach((f, index) => {
        if (f.id == friend.id) {
          // 拷贝属性
          let online = state.friends[index].online;
          Object.assign(state.friends[index], friend);
          state.friends[index].online = online;
        }
      });
    },
    activeFriend(state, idx) {
      state.activeFriend = state.friends[idx];
    },
    removeFriend(state, idx) {
      if (state.friends[idx] == state.activeFriend) {
        state.activeFriend = null;
      }
      state.friends.splice(idx, 1);
    },
    addFriend(state, friend) {
      state.friends.push(friend);
    },
    refreshOnlineStatus(state) {
      let userIds = [];
      if (state.friends.length == 0) {
        return;
      }
      state.friends.forEach((f) => {
        userIds.push(f.id);
      });
      http({
        url: "/user/terminal/online",
        method: "get",
        params: { userIds: userIds.join(",") },
      }).then((onlineTerminals) => {
        this.commit("setOnlineStatus", onlineTerminals);
      });

      // 30s后重新拉取
      state.timer && clearTimeout(state.timer);
      state.timer = setTimeout(() => {
        this.commit("refreshOnlineStatus");
      }, 30000);
    },
    setOnlineStatus(state, onlineTerminals) {
      state.friends.forEach((f) => {
        let userTerminal = onlineTerminals.find((o) => f.id == o.userId);
        if (userTerminal) {
          f.online = true;
          f.onlineWeb = userTerminal.terminals.indexOf(TERMINAL_TYPE.WEB) >= 0;
          f.onlineApp = userTerminal.terminals.indexOf(TERMINAL_TYPE.APP) >= 0;
        } else {
          f.online = false;
          f.onlineWeb = false;
          f.onlineApp = false;
        }
      });
      // 在线的在前面
      state.friends.sort((f1, f2) => {
        if (f1.online && !f2.online) {
          return -1;
        }
        if (f2.online && !f1.online) {
          return 1;
        }
        return 0;
      });
    },
    clear(state) {
      state.timer && clearTimeout(state.timer);
      state.friends = [];
      state.timer = null;
      state.activeFriend = [];
    },
  },
  actions: {
    //这里传入的id是部门id 可能没有
    loadFriend({ commit }, id) {
      // return new Promise((resolve, reject) => {
      //   http({
      //     url: "/friend/list",
      //     method: "GET",
      //   })
      //     .then((friends) => {
      //       console.log(friends, "111111");
      //       context.commit("setFriends", friends);
      //       context.commit("refreshOnlineStatus");
      //       resolve();
      //     })
      //     .catch((res) => {
      //       reject();
      //     });
      // });

      let authorization = sessionStorage.getItem("authorization");
      let url;
      if (id) {
        url =
          process.env.VUE_APP_ZN_URL +
          "/admin-api/system/user/list-all?deptId=" +
          id;
      } else {
        url = process.env.VUE_APP_ZN_URL + "/admin-api/system/user/list-all";
      }

      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: url,
          headers: {
            authorization: authorization,
          },
        })
          .then((res) => {
            let list = res.data.data;
            let friends = list.map((item) => {
              return {
                headImage: item.avatar,
                id: item.id,
                nickName: item.nickname,
                deptName: item.deptName,
              };
            });
            commit("setFriends", friends);
            commit("refreshOnlineStatus");
            resolve();
          })
          .catch((res) => {
            console.log(res);
            reject();
          });
      });
    },
  },
};
