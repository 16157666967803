<template>
  <div class="login-view">
    <div class="login-content">
      <div class="login-intro">
        <div>
          <h3>盒子IM商业版已上线(2024-10-01):</h3>
          <ul>
            <li>
              <a href="https://www.yuque.com/u1475064/mufu2a" target="_blank"
                >详细介绍文档</a
              >
            </li>
            <li>
              <a
                href="https://www.yuque.com/u1475064/imk5n2/qtezcg32q1d0dr29"
                target="_blank"
                >商业版付费说明</a
              >
            </li>
          </ul>
        </div>
        <br />
        <div>
          <h3>如果本项目对您有帮助,请在Gitee上帮忙点亮star</h3>
        </div>
        <div class="login-icons">
          <a class="login-icon">
            <img src="https://img.shields.io/badge/license-MIT-red" />
          </a>
          <a
            class="login-icon"
            href="https://gitee.com/bluexsx/box-im"
            target="_blank"
          >
            <img src="https://gitee.com/bluexsx/box-im/badge/star.svg" />
          </a>
          <a
            class="login-icon"
            href="https://github.com/bluexsx/box-im"
            target="_blank"
          >
            <img
              src="https://img.shields.io/github/stars/bluexsx/box-im.svg?style=flat&logo=GitHub"
            />
          </a>
        </div>
      </div>
      <el-form
        class="login-form"
        :model="loginForm"
        status-icon
        :rules="rules"
        ref="loginForm"
        label-width="60px"
        @keyup.enter.native="submitForm('loginForm')"
      >
        <div class="login-brand">登陆盒子IM</div>
        <el-form-item label="终端" prop="userName" v-show="false">
          <el-input
            type="terminal"
            v-model="loginForm.terminal"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="userName">
          <el-input
            type="userName"
            v-model="loginForm.userName"
            autocomplete="off"
            placeholder="用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="loginForm.password"
            autocomplete="off"
            placeholder="密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('loginForm')"
            >登陆</el-button
          >
          <el-button @click="resetForm('loginForm')">清空</el-button>
        </el-form-item>
        <div class="register">
          <router-link to="/register">没有账号,前往注册</router-link>
        </div>
      </el-form>
    </div>
    <icp></icp>
  </div>
</template>

<script>
import Icp from "../components/common/Icp.vue";
export default {
  name: "login",
  components: {
    Icp,
  },
  data() {
    var checkUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入用户名"));
      }
      callback();
    };
    var checkPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      }
      callback();
    };
    return {
      loginForm: {
        terminal: this.$enums.TERMINAL_TYPE.WEB,
        userName: "",
        password: "",
      },
      rules: {
        userName: [
          {
            validator: checkUsername,
            trigger: "blur",
          },
        ],
        password: [
          {
            validator: checkPassword,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http({
            url: "/login",
            method: "post",
            data: this.loginForm,
          }).then((data) => {
            // 保存密码到cookie(不安全)
            this.setCookie("username", this.loginForm.userName);
            this.setCookie("password", this.loginForm.password);
            // 保存token
            sessionStorage.setItem("accessToken", data.accessToken);
            sessionStorage.setItem("refreshToken", data.refreshToken);
            this.$message.success("登陆成功");
            this.$router.push("/home/chat");
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getCookie(name) {
      let reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      let arr = document.cookie.match(reg);
      if (arr) {
        return unescape(arr[2]);
      }
      return "";
    },
    setCookie(name, value) {
      document.cookie = name + "=" + escape(value);
    },
  },
  mounted() {
    this.loginForm.userName = this.getCookie("username");
    // cookie存密码并不安全，暂时是为了方便
    this.loginForm.password = this.getCookie("password");
  },
};
</script>

<style scoped lang="scss">
.login-view {
  width: 100%;
  height: 100%;
  background: #e7f1fc;
  background-size: cover;
  box-sizing: border-box;

  .login-content {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10%;

    .login-form {
      height: 340px;
      width: 400px;
      padding: 30px;
      background: white;
      opacity: 0.9;
      box-shadow: 0px 0px 1px #ccc;
      border-radius: 3%;
      overflow: hidden;
      border: 1px solid #ccc;

      .login-brand {
        line-height: 50px;
        margin: 30px 0 40px 0;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
      }

      .register {
        display: flex;
        flex-direction: row-reverse;
        line-height: 40px;
        text-align: left;
        padding-left: 20px;
      }
    }
  }
}
</style>