<template>
  <div class="user-info-mask" @click="$emit('close')">
    <div
      class="user-info"
      :style="{ left: pos.x + 'px', top: pos.y + 'px' }"
      @click.stop
    >
      <div class="user-info-box">
        <div class="avatar">
          <head-image
            :name="user.nickName"
            :url="user.headImageThumb"
            :size="60"
            :online="user.online"
            @click.native="showFullImage()"
            radius="50%"
          >
          </head-image>
        </div>
        <div>
          <div class="nick-name">{{ user.nickName }}</div>
          <div class="phone">
            <span>电话：</span>
            <span>{{ user.userName }}</span>
          </div>
        </div>
      </div>
      <div class="user-btn-group">
        <el-button
          v-show="isFriend"
          style="width: 100px"
          type="primary"
          @click="onSendMessage()"
          >发消息</el-button
        >
        <!-- <el-button v-show="!isFriend" type="primary" @click="onAddFriend()"
          >加为好友</el-button
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import HeadImage from "./HeadImage.vue";

export default {
  name: "userInfo",
  components: {
    HeadImage,
  },
  data() {
    return {};
  },
  props: {
    user: {
      type: Object,
    },
    pos: {
      type: Object,
    },
  },
  methods: {
    onSendMessage() {
      let user = this.user;
      let chat = {
        type: "PRIVATE",
        targetId: user.id,
        showName: user.nickName,
        headImage: user.headImage,
      };
      this.$store.commit("openChat", chat);
      this.$store.commit("activeChat", 0);
      if (this.$route.path != "/home/chat") {
        this.$router.push("/home/chat");
      }
      this.$emit("close");
    },
    onAddFriend() {
      this.$http({
        url: "/friend/add",
        method: "post",
        params: {
          friendId: this.user.id,
        },
      }).then((data) => {
        this.$message.success("添加成功，对方已成为您的好友");
        let friend = {
          id: this.user.id,
          nickName: this.user.nickName,
          headImage: this.user.headImageThumb,
          online: this.user.online,
        };
        this.$store.commit("addFriend", friend);
      });
    },
    showFullImage() {
      if (this.user.headImage) {
        this.$store.commit("showFullImageBox", this.user.headImage);
      }
    },
  },
  computed: {
    isFriend() {
      let friends = this.$store.state.friendStore.friends;
      let friend = friends.find((f) => f.id == this.user.id);
      return friend != undefined;
    },
  },
};
</script>

<style lang="scss">
.user-info-mask {
  background-color: rgba($color: #000000, $alpha: 0);
  position: absolute;
  width: 100%;
  height: 100%;
}

.user-info {
  position: absolute;
  width: 300px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 5px;

  .user-info-box {
    padding: 24px 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;

    .avatar {
      padding-right: 16px;
    }

    .nick-name {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 4px;
      color: #333;
    }

    .phone {
      color: #666;
      font-size: 15px;
    }

    .user-info-items {
      margin-left: 14px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .user-btn-group {
    text-align: center;
    padding: 16px 0;
  }
}
</style>